import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import clsx from "clsx";
import { isUndefined, isEmpty } from "lodash";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { validationTypes } from "./index";
import SaveIcon from "@material-ui/icons/Save";
import { useFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../constants";
import RateSwitchDropdown from "./Modules/RateSwitchDropdown";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import DownloadHistoryTable from "./DownloadHistoryTable";
import apiClient from "../../../auth/apiClient";
import ValidationService from "../../../services/ValidationAccountService";
import {
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { shallowEqual } from "react-redux";
import _ from "lodash";

const validationService = new ValidationService(apiClient);

const InlineValidationOfferForm = ({
  key,
  index,
  data,
  contractID,
  onFormSubmit,
  onDelete,
  updateValOffer,
  contractHolderID,
  schema
}) => {
  const classes = useStyles();
  const userType = useSelector(state => state.user.UserType);

  const { hasPermissions } = useHasPermissions();
  const validationAccountsEdit = hasPermissions(["validationaccounts.edit"]) && userType != USER_TYPES.ValidationAccount;
  const validationAccountsAdd = hasPermissions(["validationaccounts.add"]) && userType != USER_TYPES.ValidationAccount;
  const validationAccountsDelete = hasPermissions(["validationaccounts.delete"]) && userType != USER_TYPES.ValidationAccount;
  const validationDownloadHistory = hasPermissions(["validationdownloadhistory"]);

  const [transientValues, setTransientValues] = useState({ ...data });
  const { facilityID } = useCurrentFacility();
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const scopeAwareFacilityID = facilityGroupID || facilityID;
  const availableList = useSelector(
    (state) => {
      return _.orderBy(state.entityScope?.available, [ "name", "asc" ]);
    }, shallowEqual) ?? [];
  const [isRateSwitch, setIsRateSwitch] = useState(data?.type === "Rate Switch");
  const [isMinuteValue, setIsMinuteValue] = useState(data?.type?.includes("Time") ? true : false);
  const [isDownloadHistoryOpen, setIsDownloadHistoryOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [selectedAll, setSelectAll] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(
    data.acceptedAtEntityIDs || []
  );

  const handleCancel = () => {
    setIsDownloadHistoryOpen(false);
  };

  const { values, submitForm, errors, setFieldValue, resetForm } = useFormik({
    initialValues: {
      ...data,
      entityID: scopeAwareFacilityID,
      contractID: contractID
    },
    validationSchema: schema,
    onSubmit: valuez => {
      onFormSubmit(valuez);
      resetForm();
      setIsRateSwitch(false);
      setCurrentSelection(data.acceptedAtEntityIDs || []);
    },
    enableReinitialize: true
  });

  const submitIfChanged = e => {
    const name = e?.target?.name ?? "";
    if (
      name === "" ||
      isUndefined(values[name]) ||
      transientValues[name] == values[name]
    ) {
      return;
    }
    updateValOffer(values);
  };

  const storeTransientValue = e => {
    const name = e?.target?.name ?? undefined;
    const value = e?.target?.value ?? undefined;
    if (!isUndefined(name) && !isUndefined(value)) {
      setTransientValues(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleValidationTypeChange = async e => {
    const value = e.target?.value;
    const isRate = value === "Rate Switch";
    const isMinute = value?.includes("Time");
    await setIsRateSwitch(isRate);
    await setIsMinuteValue(isMinute);
    await setFieldValue("type", value);
  };

  const handleValidationNameChange = async e => {
    await setFieldValue("name", e.target?.value);
  };

  const handleAmountChange = async e => {
    await setFieldValue("amount", parseFloat(e.target?.value));
  };

  const handleRateBlobSelect = async e => {
    await setFieldValue("rate", e.target.value);
  };

  const handleAcceptedAtChange = async e => {
    const {
      target: { value },
    } = e;

    const acceptedAtValues = typeof value === 'string' ? value.split(',') : value;
    await setFieldValue("acceptedAtEntityIDs", acceptedAtValues);
    setCurrentSelection(acceptedAtValues);
  }

  const handleBlur = e => {
    // Don't want to update on blur if changing from other type
    // to rate switch without specifying a rate
    if (isEmpty(values["name"])) return;
    if (isRateSwitch && isEmpty(values["rate"])) return;
    if (isNaN(values["amount"])) return;
    if (facilityGroupID && isEmpty(values["acceptedAtEntityIDs"])) return;
    if (!isUndefined(values.id) || !isEmpty(values.id)) submitIfChanged(e);
  };

  const shouldBeDisabled = () => {
    if (isUndefined(contractHolderID) || (values.id && !validationAccountsEdit)) return true;
    else return false;
  };

  const handleDeleteClick = () => {
    onDelete(values.id);
  };

  const handleDownloadHistoryClick = e => {
    if (isDownloadHistoryOpen) setIsDownloadHistoryOpen(false);
    else {
      getValidationHistoryData();
      setIsDownloadHistoryOpen(true);
    }
  };

  const getValidationHistoryData = async () => {
    let result = await validationService.getValidationOfferHistory(data?.id);
    setHistoryData(result.data);
  };

  const acceptedAtSelectorDisplayValue = function(ival) {
    var ival = Array.isArray(ival) ? ival : ival.split(",");
    if (ival.length == 0) return;
    if (ival.length == 1) return availableList.filter(x => x.id === ival[0])[0].name;
    if (ival.length < availableList.length) return `${ival.length} Facilities`
    return 'All Facilities';
  };

  useEffect(() => {
    const allSelected = availableList.length > 0 &&
      availableList.every((entity) => currentSelection.includes(entity.id));
    setSelectAll(allSelected);
  }, [currentSelection, availableList]);

  const onSelect = (e) => {
    e.stopPropagation();
    if (selectedAll) {
      setFieldValue("acceptedAtEntityIDs", []);
      setCurrentSelection([]);
    } else {
      const allSelected = availableList.map((entity) => entity.id);
      setFieldValue("acceptedAtEntityIDs", allSelected);
      setCurrentSelection(allSelected);
    }
  };

  return (
    <Grid
      key={key}
      container
      className={clsx(classes.inlineRoot, "validation-offer")}
      data-name={values["name"] ?? "create-offer"}
      data-id={index}
      spacing={2}
      style={{ justify: "left" }}
    >
      <Grid item xs={12} md={6} lg={3}>
        <TextField
          data-id={index}
          className={clsx(["name", classes.flexInput])}
          name="name"
          label="Offer Name"
          fullWidth
          value={values["name"] ?? ""}
          onChange={handleValidationNameChange}
          onBlur={handleBlur}
          onFocus={storeTransientValue}
          error={errors && errors.name ? true : false}
          helperText={errors && errors.name}
          disabled={shouldBeDisabled()}
          InputProps={{
            readOnly: Boolean(shouldBeDisabled()),
            "aria-readonly": Boolean(shouldBeDisabled()),
            disabled: Boolean(shouldBeDisabled()),
            "aria-label": "Offer Name"
          }}
          inputProps={{ "data-testid": "validation-offer-name" }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={2}>
        <TextField
          id="validation-type"
          data-id={index}
          className={clsx(["validationTypeID", classes.flexInput])}
          name="type"
          label="Type"
          fullWidth
          select
          SelectProps={{
            SelectDisplayProps: { "data-testid": "validation-type" }
          }}
          onChange={handleValidationTypeChange}
          onBlur={handleBlur}
          onFocus={storeTransientValue}
          defaultValue={values["type"] ?? ""}
          value={values["type"] ?? ""}
          error={errors && errors.type ? true : false}
          helperText={errors && errors.type}
          disabled={shouldBeDisabled()}
          InputProps={{
            readOnly: Boolean(shouldBeDisabled()),
            "aria-readonly": Boolean(shouldBeDisabled()),
            disabled: Boolean(shouldBeDisabled()),
            "aria-label": "Validation Type"
          }}
        >
          {validationTypes?.map((type, index) => {
            const isSelected = values["type"] === type.value;
            return (
              <MenuItem
                key={index}
                value={type.value}
                className={clsx([
                  classes.selectedListItem,
                  "scope",
                  (isSelected ? "selected" : "")])}
              >
                {type.label}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      {isRateSwitch ? (
        <Grid item xs={12} md={6} lg={2}>
          <RateSwitchDropdown
            data-id={index}
            className={clsx([
              "rate-switch-dropdown",
              classes.rateSwitch,
              classes.flexInput
            ])}
            value={values?.rate ?? ""}
            onChange={handleRateBlobSelect}
            disabled={shouldBeDisabled()}
            onBlur={handleBlur}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={6} lg={2}>
          <TextField
            className={clsx(["input-amt", classes.flexInput])}
            data-id={index}
            name="amount"
            fullWidth
            label={`Amount ${isMinuteValue ? "(mins)" : ""}`}
            value={Math.abs(values["amount"]) ?? 0}
            onChange={handleAmountChange}
            onBlur={handleBlur}
            onFocus={storeTransientValue}
            error={errors && errors.amount ? true : false}
            helperText={errors && errors.amount}
            disabled={shouldBeDisabled()}
            InputProps={{
              readOnly: Boolean(shouldBeDisabled()),
              "aria-readonly": Boolean(shouldBeDisabled()),
              disabled: Boolean(shouldBeDisabled()),
              "aria-label": "Amount"
            }}
            inputProps={{
              "data-testid": "validation-amount",
              step: 0.01,
              min: 0
            }}
            type="number"
          />
        </Grid>
      )}
      {facilityGroupID && (
        <Grid item xs={12} md={6} lg={2}>
        <FormControl
          variant="standard"
          className={classes.selectFormControl}
          error={!!errors.acceptedAtEntityIDs}
          helperText={errors.acceptedAtEntityIDs && errors.acceptedAtEntityIDs.message}
          >
          <InputLabel id="accepted-at-label" className={classes.selectLabel}>Accepted At</InputLabel>
          <Select
            className={clsx(["accepted-at", classes.flexInput])}
            labelId="accepted-at-label"
            id="acceptedAtEntityIDs"
            name="acceptedAtEntityIDs"
            multiple
            value={values["acceptedAtEntityIDs"] ?? []}
            onChange={handleAcceptedAtChange}
            renderValue={acceptedAtSelectorDisplayValue}
            onBlur={handleBlur}
            onFocus={storeTransientValue}
            error={!!errors.acceptedAtEntityIDs}
            helperText={errors.acceptedAtEntityIDs && errors.acceptedAtEntityIDs.message}
            disabled={shouldBeDisabled()}
            InputProps={{
              readOnly: Boolean(shouldBeDisabled()),
              "aria-readonly": Boolean(shouldBeDisabled()),
              disabled: Boolean(shouldBeDisabled()),
              "aria-label": "Accepted At"
            }}
            data-testid="accepted-at"
          >
            <div>
              <Button onClick={onSelect} variant="outlined" size="small" className={clsx([classes.scopeButton,"scope button select"])} data-testid={"select-button"}>{selectedAll ? "Deselect All" : "Select All"}</Button>
            </div>
            <Divider />
            {availableList?.map((entity, index) => (
              <MenuItem
                key={index}
                value={entity.id}
                className={clsx([
                  classes.selectedListItem,
                  "scope",
                  (currentSelection.includes(entity.id) ? "selected" : "")])}
              >
                {entity.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className={clsx(["acceptedAtError", classes.acceptedAtError])} error={!!errors.acceptedAtEntityIDs}>{errors.acceptedAtEntityIDs}</FormHelperText>
          </FormControl>
        </Grid>
      )}
      {values?.id ? (
        <Grid item>
          {validationDownloadHistory && (
            <>
              <Button
                className={clsx("downloadHistoryBtn")}
                color="primary"
                variant="contained"
                onClick={handleDownloadHistoryClick}
                data-testid="history-button"
              >
                History
              </Button>
              <Dialog
                className={clsx("download-history-dialog")}
                role="download-history-dialog"
                fullWidth
                maxWidth="lg"
                open={isDownloadHistoryOpen}
              >
                <DialogContent className={clsx("download-history_dialog-content")}>
                  <DownloadHistoryTable
                    className={clsx("downloadhistory-form")}
                    handleCancel={handleCancel}
                    historyData={historyData}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    className={clsx([classes.closeBtn])}
                    data-id="closeBtn"
                    name="close"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
          {validationAccountsDelete && (
            <Button
              data-id={index}
              className={clsx("deleteBtn")}
              color="secondary"
              variant="contained"
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          )}
        </Grid>
      ) : validationAccountsAdd && !shouldBeDisabled() ? (
        <Grid container xs={12} md={6} lg={3}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Button
              data-id={index}
              className={clsx(["btn-create", classes.createBtn])}
              startIcon={<SaveIcon />}
              color="primary"
              onClick={submitForm}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item />
      )}
    </Grid>
  );
};

InlineValidationOfferForm.defaultProps = {
  onFormSubmit: () => {},
  onDelete: () => {},
  onDownloadHistory: () => {}
};

InlineValidationOfferForm.propTypes = {};

export default InlineValidationOfferForm;
